import {exist} from "../utils";
import SwiperCore, { Scrollbar, Autoplay } from 'swiper/core';
SwiperCore.use([Scrollbar, Autoplay]);
import Swiper from "swiper";

if (exist('.companies-swiper')) {
  const COMPANIES_SLIDER = new Swiper('.companies-swiper', {
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    spaceBetween: 10,
    slidesPerView: 2,
    breakpoints: {
      552: {
        slidesPerView: 4
      },
      992: {
        slidesPerView: 7
      }
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: false,
      draggable: true
    }
  })
}
