/**
 * shorten for document.querySelector
 */
export const select = el => document.querySelector(el);
/**
 * shorten for document.querySelectorAll
 */
export const selectAll = el => document.querySelectorAll(el);
/**
 * checking by selector if element exist on page
 */
export const exist = el => (typeof select(el) !== 'undefined' && select(el) !== null ? true : false);
/**
 * checking by node if element exist on page
 */
export const existDOM = el => (typeof el !== 'undefined' && el !== null ? true : false);
/**
 * checking if element has class
 */
export const hasClass = (el, className) => (el.classList ? el.classList.contains(className) : !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)')));
/**
 * adding class to element
 */
export const addClass = (el, className) => {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ' ' + className;
};
/**
 * removing class from element
 */
export const removeClass = (el, className) => {
  if (el.classList) el.classList.remove(className);
  else if (hasClass(el, className)) {
    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    el.className = el.className.replace(reg, ' ');
  }
};
/**
 * checking if any part of element currently in viewport
 */
export const readyToAnimate = el => {
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
  return vertInView && horInView;
};
